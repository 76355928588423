import { Navbar } from "@components/ui/Navbar";
import React, { FC } from "react";
import Preferences from "../components/Preferences";
import { useAuth } from "@helpers/hooks/useAuth";

const NavbarSimple: FC<{ showPreferenceIfUserAuth?: boolean }> = ({
  showPreferenceIfUserAuth = false,
}) => {
  const { auth } = useAuth();

  return (
    <Navbar fixed={true}>
      <div
        className={`flex align-middle ${
          showPreferenceIfUserAuth ? "" : "mx-auto md:mx-0"
        }`}
      >
        <Navbar.Brand />
      </div>

      {/* Show Preference only if user is authenticated */}
      {showPreferenceIfUserAuth && auth && (
        <div className="flex items-center max-md:w-24 mr-2 w-auto">
          <div className="border border-gray-300 hover:border-gray-400 p-2 w-full md:w-auto rounded px-4 text-center">
            <Preferences />
          </div>
        </div>
      )}
    </Navbar>
  );
};

export default NavbarSimple;
